@import '~antd/dist/antd.css';

.container{
  width: 90%;
  max-width: 1000px;
  margin: 0px auto;
  padding: 1rem 0rem;
}

header{
  background-color: #23458C;
}

.menu li{
  display: block;
  float: left;
  margin-left: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}

.menu li a{
  color: rgba(250,250,250, 1);
  opacity: 0.8;
}

.menu li a:hover{
  opacity: 1;
}

.section-header{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(10,10,10,0.1);
  margin-bottom: 1rem;
}

.ant-select-selection-item, .ant-select-item-option-content{
  display: flex;
  align-items: center;
}

.ant-collapse-header{
  font-weight: 600;
  font-size: 1rem;
  border-radius: 8px !important;
  background-color: #F5F6F6;
}
.ant-collapse > .ant-collapse-item{
  border-bottom: 0px;
}

.ant-collapse{
  border: 0px;
  border-radius: 8 !important;
  background: none;
}

.ant-collapse-item{
  margin-bottom: 0.6rem;
}

.ant-collapse-content{
  border-top: 0px;
}

.character-limit{
  color: #23458C;
  font-weight: 500;
}

.ant-collapse-content > .ant-collapse-content-box{
  padding-bottom: 6px;
}
.ant-descriptions-item-label{
  font-weight: 500;
}

.post{
  border: 1px solid #F6F6F6;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.04);
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  border-radius: 8px;
}
.ant-descriptions-row > th, .ant-descriptions-row > td{
  padding-bottom: 8px;
}